import React from "react"
import Layout from "../components/layout"
import {graphql, Link} from "gatsby"
import he from 'he';
import moment from 'moment';
import 'moment/locale/nl';
import Sitemap from "../components/blocks/sitemap";
import NewsBlock from "../components/newsBlock"
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw'
import Markdown from "react-markdown";

export default ({ data }) => {
    const date = moment(data.strapiArticles.Date, 'YYYY-MM-DD');
    const header = he.decode((data.strapiArticles.Title))
    // const content = `<img class="pr-10 pb-10 ml-10 h-auto w-500" src="${data.strapiArticles.Image.url}"/>${he.decode((data.strapiArticles.Content))}`
    const content = `<img class="pr-10 pb-10 ml-10 h-auto w-500" src="${data.strapiArticles.Image.url}"/>${data.strapiArticles.Content}`

    const renderers = {
        //This custom renderer changes how images are rendered
        //we use it to constrain the max width of an image to its container
        image: ({
                    alt,
                    src,
                    title,
                }) => (
            <img
                alt={alt}
                src={src}
                title={title}
                style={{ maxWidth: 500 }}  />
        ),
    };

    return <Layout>
        <div className={'container small pt-150 news-article mb-150'}>
            <h1 className={'bold t-40 lh-130'} dangerouslySetInnerHTML={{ __html: header }}/>
            <div>{date.locale('nl').format('LL')}</div>
            <div className={'content'}>
                <Markdown
                    children={content}
                    rehypePlugins={[rehypeRaw]}
                    transformImageUri={uri => uri.startsWith('http') ? uri : `${process.env.IMAGE_BASE_URL}${uri}`}
                    escapeHtml={false} />
            </div>

            {/*<div className={'content'} dangerouslySetInnerHTML={{ __html: content }} />*/}
            {<Link to={`/nieuws`} className={`mt-30 blue-button`}>Terug</Link>}
            <div>
                    <h3 className={"col-12 mt-30 t-30 bold " + ( data.relatedArticles.edges.length !== 0 ? "" : "hidden" )}>Gerelateerd nieuws</h3>
                    <ul className="hbox list-style-none">
                        {data.relatedArticles.edges.map((article) =>
                            <li className="col-4 mt-20" key={article.node.strapiId}>
                                <NewsBlock article={article.node}/>
                            </li>
                        )}
                    </ul>
            </div>
        </div>

        <Sitemap/>
    </Layout>
}

export const query = graphql`
    query ArticlesQuery($slug: String!, $allTagsFromNode: [String!]) {
        strapiArticles(Slug: { eq: $slug }) {
            strapiId
            Title
            Date
            Content
            tags {
                tag
                Slug
            }
            Image {
                url
            }
        }
        relatedArticles: allStrapiArticles(limit: 3, filter: {tags: {elemMatch: {tag: {in: $allTagsFromNode}}}, Slug: {ne: $slug}}) {
            edges {
                node {
                    tags {
                        tag
                        Slug
                    }
                    Content
                    Date
                    Image {
                        url
                    }
                    strapiId
                    Slug
                    Title
                }
            }
        }
    }
`;

// export const postQuery = graphql`
//   query NewsPostById($id: String!, $tag: String) {
//     news(id: { eq: $id }) {
//       id
//       title
//       image
//       text
//       date(formatString: "DD-MM-YYYY")
//       tags
//     }
//     relatedNews: allNews (limit: 3, filter: {tags: { in: [$tag] }, id: { ne: $id } } ) {
//       edges {
//         node {
//           id
//           title
//           image
//           text
//           date(formatString: "DD-MM-YYYY")
//           tags
//         }
//       }
//     }
//   }
// `
